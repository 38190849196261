const locales = ['en', 'pt'];
const defaultLocale = 'en';
const browserLocale = navigator.language.split(/[-_]/)[0];
const locale = locales.find((lang) => lang === browserLocale) || defaultLocale;

const appConfig = {
  apiPrefix: '/api',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale,
  enableMock: false,
};

export default appConfig;
