import React from 'react';
import { Loading } from 'components/shared';
import { Button, Tooltip } from 'components/ui';
import { RiDeleteBin6Line } from 'react-icons/ri';
import CreatePageDialog from './CreatePageDialog';
import DeletePageDialog from './DeletePageDialog';

const Pages = ({
  loading,
  loadingCreatePage,
  loadingDeletePage,
  pages,
  createPageDialog,
  deletePageDialog,
  handleOpenCreatePageDialog,
  handleCloseCreatePageDialog,
  handleOpenDeletePageDialog,
  handleCloseDeletePageDialog,
  handleCreatePage,
  handleDeletePage,
}) => {
  if (!pages || loading) {
    return <Loading loading={true} />;
  }

  const createDialog = (
    <CreatePageDialog
      isOpen={createPageDialog}
      handleClose={handleCloseCreatePageDialog}
      handleAddPage={handleCreatePage}
      loading={loadingCreatePage}
    />
  );

  const deleteDialog = (
    <DeletePageDialog
      isOpen={!!deletePageDialog}
      handleClose={handleCloseDeletePageDialog}
      handleDeletePage={handleDeletePage}
      page={deletePageDialog}
      loading={loadingDeletePage}
    />
  );

  return (
    <>
      {createDialog}
      {deleteDialog}

      <div className="flex items-center justify-between">
        <h4>Páginas</h4>
        <Button type={'button'} onClick={handleOpenCreatePageDialog}>
          Adicionar página
        </Button>
      </div>

      {pages?.length > 0 ? (
        <div className="flex flex-col gap-2 mt-4">
          {pages.map((page, index) => (
            <div
              key={page.url || index}
              className="flex items-center justify-between gap-8 border-t border-gray-200 dark:border-gray-700 py-2"
            >
              <div className="flex flex-col mr-auto break-all">
                <strong className="text-gray-700 dark:text-gray-200">
                  Página {index + 1}
                </strong>
                <a href={page.url || page} target="_blank" className="hover:underline">
                  <span>{page.url || page}</span>
                </a>
              </div>

              <Tooltip title="Remover" placement="left-start">
                <Button
                  size="sm"
                  icon={<RiDeleteBin6Line size={16} />}
                  onClick={() => handleOpenDeletePageDialog(page)}
                ></Button>
              </Tooltip>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-500 dark:text-gray-400 p-16 flex justify-center items-center flex-col gap-4">
          <p className="text-center text-lg">
            Páginas do seu site relacionadas para exibição
          </p>
          <Button onClick={handleOpenCreatePageDialog} variant="solid" type="button">
            Começar
          </Button>
        </div>
      )}
    </>
  );
};

export default Pages;
