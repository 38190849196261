export const parseGraphQLError = (error) => {
  const { graphQLErrors: errors } = error;
  if (errors?.length) {
    const { body } = errors[0]?.extensions?.internal?.response || {};
    return (
      body || {
        error: {
          code: 'global/internal-server-error',
          message: 'Something went wrong',
        },
      }
    );
  }
  return error;
};
