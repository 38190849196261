import { auth } from 'services/firebase';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER, LOGIN, REGISTER, FORGOT_PASSOWRD, GOOGLE_SIGN_IN } from './auth.gql';
import { useDispatch } from 'react-redux';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { useNavigate } from 'react-router-dom';
import useQueryParams from 'utils/hooks/useQuery';
import { parseGraphQLError } from 'utils/errors';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQueryParams();

  const local = localStorage.getItem('streamcart_user');
  const [user, setUser] = useState(local ? JSON.parse(local) : null);

  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER, {
    skip: !localStorage.getItem('token'),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [login] = useMutation(LOGIN);
  const [googleSignIn] = useMutation(GOOGLE_SIGN_IN);
  const [register] = useMutation(REGISTER);
  const [forgotPassword] = useMutation(FORGOT_PASSOWRD);

  const configToken = async (token) => {
    if (!token) return;
    localStorage.setItem('token', token);
    window.location.reload();
  };

  const handleSignUp = async ({
    email,
    password,
    firstname,
    lastname,
    telephone,
    company,
  }) => {
    try {
      const { data } = await register({
        variables: {
          input: {
            email,
            password,
            firstname,
            lastname,
            telephone,
            company,
          },
        },
      });
      configToken(data?.register?.access_token);
    } catch (error) {
      localStorage.removeItem('token');
      return parseGraphQLError(error);
    }
  };

  const handleSignIn = async ({ email, password }) => {
    try {
      const { data } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      configToken(data?.login?.access_token);
    } catch (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      return parseGraphQLError(error);
    }
  };

  const handleGoogleSignIn = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    try {
      const { user } = await signInWithPopup(auth, provider);
      if (user) {
        const { accessToken, uid, displayName, email, photoURL } = user;

        await googleSignIn({
          variables: {
            input: {
              access_token: accessToken,
              uid,
              display_name: displayName,
              email,
              photo_url: photoURL,
            },
          },
        });
        configToken(accessToken);
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  const handleForgotPassword = async ({ email }) => {
    try {
      const { data } = await forgotPassword({
        variables: {
          email,
        },
      });
      return data?.forgotPassword;
    } catch (error) {
      return parseGraphQLError(error);
    }
  };

  const handleSignOut = async () => {
    dispatch(onSignOutSuccess());
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('streamcart_user');
    window.location.reload();
  };

  useEffect(() => {
    if (data?.users?.length) {
      const user = data.users[0];
      const props = { ...user, authority: ['USER'] };
      setUser((prev) => ({ ...prev, ...props }));
      localStorage.setItem('streamcart_user', JSON.stringify(props));
    }
  }, [data]);

  useEffect(() => {
    if (localStorage.getItem('token') && !user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const localToken = localStorage.getItem('token');

      if (user) {
        const { refreshToken, accessToken } = user;
        localStorage.setItem('refresh_token', refreshToken);

        if (accessToken && accessToken !== localToken) {
          localStorage.setItem('token', user.accessToken);
        }
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    loading,
    authenticated: !!user,
    user,
    getUser,
    setUser,
    handleSignIn,
    handleSignUp,
    handleSignOut,
    handleForgotPassword,
    handleGoogleSignIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
