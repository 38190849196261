import React, { useState } from 'react';
import { Dialog, Alert } from 'components/ui';
import { Input, Button, FormItem } from 'components/ui';
import { Formik, Form, Field } from 'formik';
import { useStore } from 'context/Store';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

const CreatePageDialog = ({ isOpen, handleClose: close, handleAddPage, loading }) => {
  const navigate = useNavigate();
  const { store } = useStore();
  const { site_url, verified } = store || {};

  const local =
    process.env.NODE_ENV === 'development' || window?.location?.hostname === 'localhost';
  const validationSchema = Yup.object().shape(
    local
      ? {}
      : {
          url: Yup.string()
            .required('Campo obrigatório')
            .matches(new RegExp(`^${site_url}.*$`), `A URL deve começar com ${site_url}`),
        }
  );

  const { hostname } = window.location;

  const [error, setError] = useState(null);
  const [getPageByUrl, { data }] = useLazyQuery(GET_PAGE_BY_URL);

  const handleClose = () => {
    setError(null);
    close();
  };

  return (
    <Dialog
      width={620}
      isOpen={isOpen}
      onClose={handleClose}
      onRequestClose={handleClose}
    >
      <div className="flex flex-col">
        <h4>Adicionar página</h4>
        {!verified || local ? (
          <p className="text-sm text-gray-500">
            Para adicionar uma página, você precisa configurar e verificar a URL do seu
            site.
          </p>
        ) : (
          <p>Adicione uma URL do seu site ou use * para aplicar em todo o site</p>
        )}
      </div>

      {error && (
        <div className="mt-4 mb-4 text-red-500 text-sm">
          <Alert type="danger" title={'Erro ao configurar página'}>
            {error}
          </Alert>
        </div>
      )}

      {verified || local ? (
        <Formik
          initialValues={{ url: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const response = await getPageByUrl({
                variables: { url: values.url },
              });
              // set error if page already exists
              if (response?.data?.pages?.length) {
                setError('A URL informada já esta associada a outro vídeo ou live');
                return;
              }

              await handleAddPage(values);
              setError(null);
              handleClose();
            } catch (error) {
              return;
            }
          }}
        >
          {({ values, touched, errors }) => {
            return (
              <Form>
                <div className="flex flex-col gap-4 mt-8 mb-8">
                  <FormItem
                    label="URL"
                    invalid={errors.url && touched.url}
                    errorMessage={errors.url}
                    className="mb-0"
                  >
                    <Field name="url" as={Input} placeholder="https://..." required />
                  </FormItem>
                </div>

                <div className="flex justify-center gap-4 mt-2 mb-4">
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button type="submit" variant="solid" loading={loading}>
                    Adicionar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="flex items-center justify-center mt-12 mb-8">
          <Button variant="solid" onClick={() => navigate('/integrations')}>
            Configurar agora
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default CreatePageDialog;

export const GET_PAGE_BY_URL = gql`
  query getPageByUrl($url: String!) {
    pages(where: { url: { _eq: $url } }) {
      id
      url
    }
  }
`;
