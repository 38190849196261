import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STORE } from './store.gql';

const StoreContext = React.createContext();

export function useStore() {
  return useContext(StoreContext);
}

export default function StoreProvider({ children }) {
  const { data, loading, error } = useQuery(GET_STORE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [store, setStore] = useState(null);

  useEffect(() => {
    if (data?.stores?.length) {
      setStore(data.stores[0]);
    }
  }, [data]);

  const value = {
    loading,
    store,
    setStore,
  };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}
