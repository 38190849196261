import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import { useDispatch } from 'react-redux';
import appConfig from 'configs/app.config';
import './locales';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  concat,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { onSignOutSuccess } from 'store/auth/sessionSlice';
import { firebaseConfig } from 'services/firebase';

import AuthProvider from 'context/Auth';
import StoreProvider from 'context/Store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientContainer>
          <BrowserRouter>
            <AuthProvider>
              <StoreProvider>
                <Theme>
                  <Layout />
                </Theme>
              </StoreProvider>
            </AuthProvider>
          </BrowserRouter>
        </ClientContainer>
      </PersistGate>
    </Provider>
  );
}

const refetchRefreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (firebaseConfig.apiKey && refreshToken) {
    const url = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    });

    const data = await response.json();
    const token = data?.access_token;

    if (token) {
      localStorage.setItem('token', token);
      window.location.reload();
    }
  }
};


export const ClientContainer = ({ children }) => {
  const uri = process.env.REACT_APP_HASURA_ENDPOINT;
  const token = localStorage.getItem('token');
  const headers = token ? { authorization: `Bearer ${token}` } : {};

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const arr = [];
    if (networkError) arr.push(networkError);
    if (graphQLErrors) arr.push(...graphQLErrors);

    if (arr?.length > 0)
      arr.forEach((item) => {
        const { message, locations, path } = item || {};
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (message?.includes('Could not verify JWT')) {
          refetchRefreshToken();
        }
      });
  });

  const httpLink = new HttpLink({ uri });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: uri?.replace('https', 'wss').replace('http', 'ws'),
      connectionParams: {
        headers,
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers: ctxHeaders = {} }) => {
      return {
        headers: {
          ...ctxHeaders,
          ...headers,
        },
      };
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, concat(authMiddleware, splitLink)]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
